import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { getNavItems } from "../constants/index";
import logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";

const languageOptions = [
  { code: "pl", label: "Polish" },
  { code: "en", label: "English" },
  { code: "de", label: "German" },
];

const Navbar = () => {
  const { t } = useTranslation();
  const navItems = getNavItems(t);
  const { i18n } = useTranslation();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("pl");

  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setCurrentLanguage(languageCode);
    setLanguageDropdownOpen(false);
    // Handle additional logic for changing language here (e.g., translation)
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <nav className="sticky top-0 z-50 py-3 border-b backdrop-blur-lg border-neutral-700/80">
      <div className="container relative px-4 mx-auto lg:text-sm">
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-shrink-0">
            <img
              className="w-10 h-10 mr-2"
              src={logo}
              alt="Logo"
              width={"full"}
              height={"full"}
              loading="eager"
              title="logo"
            />
            <span className="text-xl tracking-tight">HyperBay</span>
          </div>
          <ul className="hidden space-x-12 lg:flex ml-14">
            {navItems.map((item, index) => (
              <li
                key={index}
                className="transition-transform duration-200 transform hover:text-orange-500 hover:scale-110"
              >
                <a href={item.href}>{item.label}</a>
              </li>
            ))}
            <li className="relative">
              <button
                className="transition-transform duration-200 transform hover:text-orange-500 hover:scale-110"
                onClick={toggleLanguageDropdown}
              >
                {
                  languageOptions.find((lang) => lang.code === currentLanguage)
                    .label
                }
              </button>
              {languageDropdownOpen && (
                <ul className="absolute mt-2 border rounded-sm bg-neutral-900 border-neutral-700">
                  {languageOptions.map((lang) => (
                    <li
                      key={lang.code}
                      className="p-2 cursor-pointer hover:text-orange-500 hover:bg-neutral-800"
                      onClick={() => changeLanguage(lang.code)}
                    >
                      {lang.label}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
          <div className="flex-col justify-end lg:hidden md:flex">
            <button onClick={toggleNavbar}>
              {mobileDrawerOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
            </button>
          </div>
        </div>
        <div
          className={`fixed right-0 z-20 flex flex-col h-[70vh] items-center justify-center w-full p-5 text-center border-b bg-neutral-900 lg:hidden transition-all duration-300 ${
            mobileDrawerOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <ul className="flex flex-col gap-10">
            {navItems.map((item, index) => (
              <li
                key={index}
                className="p-2 transition-transform duration-200 transform border-b rounded-sm hover:text-orange-500 hover:scale-105"
              >
                <a href={item.href}>{item.label}</a>
              </li>
            ))}
            <li className="relative">
              <button
                className="transition-transform duration-200 transform hover:text-orange-500 hover:scale-105 "
                onClick={toggleLanguageDropdown}
              >
                {
                  languageOptions.find((lang) => lang.code === currentLanguage)
                    .label
                }
              </button>
              {languageDropdownOpen && (
                <ul className="absolute mt-2 transform -translate-x-1/2 border rounded-sm left-1/2 bg-neutral-900 border-neutral-700">
                  {languageOptions.map((lang) => (
                    <li
                      key={lang.code}
                      className="p-2 cursor-pointer hover:text-orange-500 hover:bg-neutral-800"
                      onClick={() => changeLanguage(lang.code)}
                    >
                      {lang.label}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
