import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "./i18n.js";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.Suspense fallback="loading...">
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.Suspense>
  </BrowserRouter>
);
