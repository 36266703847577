import { ReactTyped } from "react-typed";
import Reveal from "../Animation/Reveal";
import video1 from "../../assets/video1.mp4";
import video2 from "../../assets/video2.mp4";

import { useTranslation } from "react-i18next";

const Herosection = () => {
  const { t } = useTranslation();
  return (
    <Reveal>
      <div className="flex flex-col items-center p-4 mt-6 lg:mt-20">
        <h1 className="text-4xl tracking-wide text-center sm:text-6xl lg:text-7xl">
          {t("mainTitle")}
          <br />
          <ReactTyped
            className="text-transparent bg-gradient-to-r from-orange-500 to-red-800 bg-clip-text"
            strings={[
              t("mainTitleText1"),
              t("mainTitleText2"),
              t("mainTitleText3"),
            ]}
            typeSpeed={50}
            backSpeed={50}
            loop
          />
        </h1>
        <p className="max-w-4xl mt-10 text-lg text-center text-neutral-500">
          {t("mainText")}
        </p>
        <div className="flex justify-center my-10">
          <a
            href="/oferta"
            className="px-4 py-3 mx-3 transition-transform duration-200 transform rounded-md bg-gradient-to-r from-orange-500 to-orange-800 hover:scale-110"
          >
            {t("mainBtnone")}
          </a>
          <a
            href="/realizacja"
            className="px-4 py-3 mx-3 transition-transform duration-200 transform border rounded-md hover:scale-110"
          >
            {t("mainBtontwo")}
          </a>
        </div>
        <div className="flex justify-center mt-10">
          <video
            autoPlay
            loop
            muted
            className="md:w-[30%] md:h-[30%] w-[50%] h-[50%] mx-2 my-4 border border-orange-700 rounded-lg shadow-sm shadow-orange-400"
          >
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video
            autoPlay
            loop
            muted
            className="md:w-[30%] md:h-[30%] w-[50%] h-[50%] mx-2 my-4 border border-orange-700 rounded-lg shadow-sm shadow-orange-400"
          >
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Reveal>
  );
};

export default Herosection;
