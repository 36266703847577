import { Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/utility/Footer";
import Kontakt from "./page/Kontakt";
import Oferta from "./page/Oferta";
import Realizacja from "./page/Realizacja";
import Onas from "./page/Onas";
import NotFound from "./page/NotFound"; // Importuj komponent NotFound

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/onas" element={<Onas />} />
        <Route path="/realizacja" element={<Realizacja />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/oferta" element={<Oferta />} />
        {/* Dodaj trasę dla nieznalezionych stron */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
