import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Kontakt = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_0430nyt", "template_of12rdy", form.current, {
        publicKey: "KEqBSAm2ZsyZrO85L",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setIsSuccess(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <>
      {" "}
      <Helmet>
        <title>Kontakt</title>
        <meta
          name="description"
          content="Skontaktuj się z nami - szybki i łatwy dostęp do profesjonalnej obsługi klienta"
        />
        <link rel="canonical" href="/kontakt" />
      </Helmet>
      <div class="mt-20">
        <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 class=" text-3xl tracking-wide text-center sm:text-5xl lg:text-6xl">
            {t("kontaktTitle")}
          </h2>
          <p class="max-w-4xl mt-10 text-lg text-center text-neutral-500">
            {t("kontaktTekst")}
          </p>
          <form action="#" class="space-y-8" ref={form} onSubmit={sendEmail}>
            <div>
              <label for="email" class=" text-orange-500">
                Email
              </label>
              <input
                type="email"
                id="email"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                placeholder="name@flowbite.com"
                required
              />
            </div>
            <div>
              <label for="subject" class="text-orange-500 ">
                {t("kontaktTemat")}
              </label>
              <input
                type="text"
                id="subject"
                class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
                placeholder={t("kontaktTemat")}
                required
              />
            </div>
            <div>
              <label for="phone" class="text-orange-500 ">
                {t("kontaktNumer")}
              </label>
              <input
                type="tel"
                id="phone"
                class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
                placeholder="+ 00 123 456 789"
                required
              />
            </div>
            <div class="sm:col-span-2">
              <label for="message" class="text-orange-500 ">
                {t("kontaktWiadomosc")}
              </label>
              <textarea
                id="message"
                rows="6"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                placeholder={t("kontaktWiadomosc")}
              ></textarea>
            </div>
            <button
              type="submit"
              class="bg-transparent text-orange-500 font-semibold py-2 px-4 border border-orange-500 rounded transition duration-300 hover:bg-orange-500 hover:text-white hover:border-transparent"
            >
              {t("kontaktWyslij")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Kontakt;
