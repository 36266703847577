import {
  RxCrop,
  RxDesktop,
  RxPencil2,
  RxReader,
  RxRocket,
} from "react-icons/rx";
import { FaEye } from "react-icons/fa";

import {
  SlSocialFacebook,
  SlSocialGoogle,
  SlSocialInstagram,
  SlSocialLinkedin,
} from "react-icons/sl";

import user1 from "../assets/profile-pictures/user1.jpg";
import user2 from "../assets/profile-pictures/user2.jpg";
import user3 from "../assets/profile-pictures/user3.jpg";
import user4 from "../assets/profile-pictures/user4.jpg";
import user5 from "../assets/profile-pictures/user5.jpg";
import user6 from "../assets/profile-pictures/user6.jpg";

import thumb1 from "../assets/thub2.png";
import thumb2 from "../assets/thub3.png";

export const getNavItems = (t) => [
  { label: "Home", href: "/" },
  { label: t("navBarAbout"), href: "/onas" },
  { label: t("navBarRealizacja"), href: "/realizacja" },
  { label: t("navBarOferta"), href: "/oferta" },
  { label: t("navBarKontakt"), href: "/kontakt" },
];

export const prace = [
  { images: thumb1, href: "http://lechbud.de/", title: "lechbud.de" },
  { images: thumb2, href: "https://robeks.vercel.app/", title: "robeks.de" },
];
export const getTestimonials = (t) => [
  {
    user: "Robert",
    company: "Robeks",
    image: user1,
    text: t("testimonialsText1"),
  },
  {
    user: "Marcin",
    company: "Lechbud",
    image: user3,
    text: t("testimonialsText2"),
  },
  {
    user: "Aleksandra Herzog",
    company: "Robeks",
    image: user2,
    text: t("testimonialsText3"),
  },
  {
    user: "Ronee Brown",
    company: "Fusion Dynamics",
    image: user4,
    text: t("testimonialsText4"),
  },
];

export const getFeatures = (t) => [
  {
    icon: <RxCrop />,
    text: "Branding",
    description: t("brandingText"),
  },
  {
    icon: <RxPencil2 />,
    text: "Design",
    description: t("designText"),
  },
  {
    icon: <RxDesktop />,
    text: "Development",
    description: t("developmentText"),
  },
  {
    icon: <RxReader />,
    text: "Copywriting",
    description: t("copywritingText"),
  },
  {
    icon: <RxRocket />,
    text: "SEO",
    description: t("seoText"),
  },
  {
    icon: <FaEye />,
    text: "Wizualizacje",
    description: t("wizText"),
  },
];

export const getChecklistItems = (t) => [
  {
    title: t("workflowTextTitle1"),
    description: t("workflowText1"),
  },
  {
    title: t("workflowTextTitle2"),
    description: t("workflowText2"),
  },
  {
    title: t("workflowTextTitle3"),
    description: t("workflowText3"),
  },
  {
    title: t("workflowTextTitle4"),
    description: t("workflowText4"),
  },
];

export const getPricingOptions = (t) => [
  {
    title: t("pricingTileOne"),
    price: t("pricingPriceOne"),
    features: [
      t("pricingOnetext1"),
      t("pricingOnetext2"),
      t("pricingOnetext3"),
      t("pricingOnetext4"),
    ],
  },
  {
    title: t("pricingTileTwo"),
    price: t("pricingPriceTwo"),
    features: [
      t("pricingTwotext1"),
      t("pricingTwotext2"),
      t("pricingTwotext3"),
      t("pricingTwotext4"),
    ],
  },
  {
    title: t("pricingTileThree"),
    price: t("pricingPriceThree"),
    features: [
      t("pricingThreetext1"),
      t("pricingThreetext2"),
      t("pricingThreetext3"),
      t("pricingThreetext4"),
    ],
  },
];

export const getStronyOpcje = (t) => [
  {
    title: t("stronaOnePageTitle"),
    price: t("stronaOnePagePrice"),
    features: [
      t("stronaOnePageFeature1"),
      t("stronaOnePageFeature2"),
      t("stronaOnePageFeature3"),
      t("stronaOnePageFeature4"),
      t("stronaOnePageFeature5"),
      t("stronaOnePageFeature6"),
      t("stronaOnePageFeature7"),
    ],
  },
  {
    title: t("stronaStandardTitle"),
    price: t("stronaStandardPrice"),
    features: [
      t("stronaStandardFeature1"),
      t("stronaStandardFeature2"),
      t("stronaStandardFeature3"),
      t("stronaStandardFeature4"),
      t("stronaStandardFeature5"),
      t("stronaStandardFeature6"),
      t("stronaStandardFeature7"),
    ],
  },
  {
    title: t("stronaPremiumTitle"),
    price: t("stronaPremiumPrice"),
    features: [
      t("stronaPremiumFeature1"),
      t("stronaPremiumFeature2"),
      t("stronaPremiumFeature3"),
      t("stronaPremiumFeature4"),
      t("stronaPremiumFeature5"),
      t("stronaPremiumFeature6"),
      t("stronaPremiumFeature7"),
    ],
  },
];

export const getSocialOpcje = (t) => [
  {
    title: t("socialBronzeTitle"),
    price: t("socialBronzePrice"),
    features: [
      t("socialBronzeFeature1"),
      t("socialBronzeFeature2"),
      t("socialBronzeFeature3"),
      t("socialBronzeFeature4"),
    ],
  },
  {
    title: t("socialSilverTitle"),
    price: t("socialSilverPrice"),
    features: [
      t("socialSilverFeature1"),
      t("socialSilverFeature2"),
      t("socialSilverFeature3"),
      t("socialSilverFeature4"),
      t("socialSilverFeature5"),
    ],
  },
  {
    title: t("socialGoldTitle"),
    price: t("socialGoldPrice"),
    features: [
      t("socialGoldFeature1"),
      t("socialGoldFeature2"),
      t("socialGoldFeature3"),
      t("socialGoldFeature4"),
      t("socialGoldFeature5"),
    ],
  },
];

export const getGrafikiOpcje = (t) => [
  {
    title: t("grafikiLogoTitle"),
    price: t("grafikiLogoPrice"),
    features: [t("grafikiLogoFeature1"), t("grafikiLogoFeature2")],
  },
  {
    title: t("grafikiPromujaceTitle"),
    price: t("grafikiPromujacePrice"),
    features: [
      t("grafikiPromujaceFeature1"),
      t("grafikiPromujaceFeature2"),
      t("grafikiPromujaceFeature3"),
    ],
  },
];

export const getResourcesLinks = (t) => [
  { href: "/onas", text: t("navBarAbout") },
  { href: "/realizacja", text: t("navBarRealizacja") },
  { href: "/oferta", text: t("navBarOferta") },
  { href: "/kontakt", text: t("navBarKontakt") },
  { href: "/polityka-prywatnosci", text: t("footerPolityka") },
];

export const communityLinks = [
  {
    href: "https://www.facebook.com/share/jkAKfMQ6DkUE3Feu/",
    icon: <SlSocialFacebook size={20} />,
  },
  { href: "#", icon: <SlSocialGoogle size={20} /> },
  {
    href: "https://www.instagram.com/hyperbaystudio?igsh=MWtuOGt4M254cnQ3MA==",
    icon: <SlSocialInstagram size={20} />,
  },
  { href: "#", icon: <SlSocialLinkedin size={20} /> },
];
