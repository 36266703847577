import { FaRegCheckCircle } from "react-icons/fa";
import codeImg from "../../assets/code.jpg";
import { getChecklistItems } from "../../constants/index";
import Reveal from "../Animation/Reveal";
import { useTranslation } from "react-i18next";

const Workflow = () => {
  const { t } = useTranslation();
  const checklistItems = getChecklistItems(t);
  return (
    <div className="mt-20">
      <Reveal>
        <h1 className="mt-6 text-3xl tracking-wide text-center sm:text-5xl lg:text-6xl">
          {t("workflowTitleOne")}{" "}
          <span className="text-transparent bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text">
            {t("workflowTitleTwo")}
          </span>
        </h1>
      </Reveal>
      <Reveal>
        <div className="flex flex-wrap justify-center">
          <div class="w-full p-2 lg:w-1/2">
            <img
              src={codeImg}
              alt="Coding"
              width="full"
              height="full"
              class="object-cover"
              loading="lazy"
              title="code"
            />
          </div>

          <div className="w-full pt-12 lg:w-1/2">
            {checklistItems.map((item, index) => (
              <div key={index} className="flex mb-12">
                <div className="items-center justify-center w-10 h-10 p-2 mx-6 text-green-400 rounded-full bg-neutral-900">
                  <FaRegCheckCircle size={24} />
                </div>
                <div>
                  <h5 className="mt-1 mb-2 text-xl">{item.title}</h5>
                  <p className="text-md text-neutral-500">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default Workflow;
