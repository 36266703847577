import { getResourcesLinks, communityLinks } from "../../constants/index";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  const resourcesLinks = getResourcesLinks(t);
  return (
    <footer className="p-10 mt-20 border-t border-neutral-700">
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 ">
        <div>
          <h3 className="mb-4 font-semibold text-md">Linki</h3>
          <ul className="space-y-2">
            {resourcesLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className="text-neutral-300 hover:text-white"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="mb-4 font-semibold text-md">{t("footerDane")}</h3>
          <ul className="space-y-2">
            <li>Nip:6941699231</li>
            <li>
              Email:
              <a
                href="mailto:biuro@hyperbaystudio.com"
                className="text-neutral-300 hover:text-white"
              >
                biuro@hyperbaystudio.com
              </a>
            </li>
            <li>
              Tel:
              <a
                href="tel:+48 789 399 297"
                className="text-neutral-300 hover:text-white"
              >
                +48 789 399 297
              </a>
            </li>
            <li>
              Tel:
              <a
                href="tel:+48 797 470 216"
                className="text-neutral-300 hover:text-white"
              >
                +48 797 470 216
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mb-4 font-semibold text-md">{t("footerIconText")}</h3>
          <ul className="flex gap-4">
            {communityLinks.map((link, index) => (
              <li key={index} className="">
                <a
                  href={link.href}
                  className="text-neutral-300 hover:text-white"
                >
                  {link.icon}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
