// import swiper react components
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Reveal from "../Animation/Reveal";
// icons
import { BsArrowRight } from "react-icons/bs"; // work slider data

import { prace } from "../../constants/index";
import { useTranslation } from "react-i18next";

const RealizacjeMain = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-20">
      <Reveal>
        <h2 className="pb-6 mt-6 text-3xl tracking-wide text-center sm:text-5xl lg:text-6xl">
          {t("realizacjaTileone")}{" "}
          <span className="text-transparent bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text">
            {t("realizacjaTiletwo")}
          </span>
        </h2>
      </Reveal>
      <Reveal>
        <div className="flex flex-col gap-4 px-2 sm:flex-row">
          <div>
            <p className="max-w-4xl mt-10 text-lg text-center text-neutral-500">
              {t("realizacjaText")}
            </p>
          </div>
          <Swiper
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="w-full "
          >
            {prace.map((prace, index) => (
              <SwiperSlide key={index}>
                <div
                  className="relative flex items-center justify-center overflow-hidden rounded-lg group"
                  key={index}
                >
                  <div className="relative flex items-center justify-center overflow-hidden group">
                    {/* image */}
                    <img
                      src={prace.images}
                      width={500}
                      height={300}
                      alt="imgRealizacja"
                      className="rounded-lg"
                    />
                    <h1 className="absolute text-2xl underline top-5">
                      {prace.title}
                    </h1>
                    {/* overlay gradient */}
                    <div className="absolute inset-0 bg-gradient-to-l from-transparent via-[#e838cc] to-[#4a22bd] opacity-0 group-hover:opacity-80 transition-all duration-700"></div>
                    {/* title */}

                    <div className="absolute bottom-0 transition-all duration-300 translate-y-full group-hover:-translate-y-10 group-hover:xl:-translate-y-20">
                      <a
                        href={prace.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="flex items-center gap-x-2 text-[13px] tracking-[0.2em]">
                          {/* title part 1 */}
                          <div className="delay-100">
                            {t("realizacjaLiveone")}
                          </div>
                          {/* title part 2 */}
                          <div className="translate-y-[500%] group-hover:translate-y-0 transition-all duration-300 delay-150">
                            {t("realizacjaLivetwo")}
                          </div>
                          {/* icon */}
                          <div className="text-xl translate-y-[500%] group-hover:translate-y-0 transition-all duration-300 delay-200">
                            <BsArrowRight />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Reveal>
    </div>
  );
};

export default RealizacjeMain;
