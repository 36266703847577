import Workflow from "../components/Onas/Workflow";
import About from "../components/Onas/About";
import { Helmet } from "react-helmet-async";

const Onas = () => {
  return (
    <>
      <Helmet>
        <title>About us</title>
        <meta
          name="description"
          content="Dowiedz się więcej o naszej firmie - pasja i doświadczenie w projektowaniu stron, logo i grafik"
        />
        <link rel="canonical" href="/onas" />
      </Helmet>
      <div>
        <Workflow />
      </div>{" "}
    </>
  );
};
export default Onas;
