import logo1 from "../../assets/Wizytowki/logo1.jpg";
import logo2 from "../../assets/Wizytowki/logo2.png";
import logo3 from "../../assets/Wizytowki/logo3.jpeg";
import logo4 from "../../assets/Wizytowki/logo4.jpeg";
import logo5 from "../../assets/Wizytowki/logo5.jpeg";
import logo6 from "../../assets/Wizytowki/logo6.jpeg";
import logo7 from "../../assets/Wizytowki/logo7.jpg";
import logo8 from "../../assets/Wizytowki/logo8.jpg";

import wiz1 from "../../assets/Wizytowki/wiz1.png";
import wiz2 from "../../assets/Wizytowki/wiz2.png";
import wiz3 from "../../assets/Wizytowki/wiz3.png";

export const logoimgs = [
  { url: logo1 },
  { url: logo2 },
  { url: logo3 },
  { url: logo4 },
  { url: logo5 },
  { url: logo6 },
  { url: logo7 },
  { url: logo8 },
];

export const wizimgs = [
  { url: wiz1 },
  { url: wiz2 },
  { url: wiz3 },
  { url: wiz1 },
  { url: wiz2 },
  { url: wiz3 },
];
