import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { getTestimonials } from "../../constants/index";
import { useTranslation } from "react-i18next";
import Reveal from "../Animation/Reveal";

const TestimonialsSection = () => {
  const { t } = useTranslation();
  const testimonials = getTestimonials(t);
  return (
    <div className="mt-20 tracking-wide">
      <Reveal>
        <h2 className="my-10 text-3xl text-center sm:text-5xl lg:text-6xl lg:my-20">
          {t("testimonialsTitle")}
        </h2>
        <Swiper
          pagination={{ dynamicBullets: true }}
          modules={[Pagination]}
          className="flex flex-wrap justify-center"
          breakpoints={{
            768: {
              slidesPerView: 3,
            },
            0: {
              slidesPerView: 1,
            },
          }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="w-full px-4 py-2 ">
                <div className="p-6 font-thin border rounded-md bg-neutral-900 text-md border-neutral-800">
                  <p>{testimonial.text}</p>
                  <div className="flex items-start mt-8">
                    <img
                      className="w-12 h-12 mr-6 border rounded-full border-neutral-300"
                      src={testimonial.image}
                      alt="testimonial"
                      width={"48px"}
                      height={"48px"}
                      loading="lazy"
                      title="face"
                    />

                    <div>
                      <h6>{testimonial.user}</h6>
                      <span className="text-sm italic font-normal text-neutral-600">
                        {testimonial.company}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Reveal>
    </div>
  );
};

export default TestimonialsSection;
