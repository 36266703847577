import RealizacjaWizytowki from "../components/Realizacja/RealizacajWizytowki";
import RealizacjaLoga from "../components/Realizacja/RealizacjaLoga";
import RealizacjaMain from "../components/Realizacja/RealizacjaMain";
import { Helmet } from "react-helmet-async";

const Realizacja = () => {
  return (
    <>
      <Helmet>
        <title>Realizacja</title>
        <meta
          name="description"
          content="Przykłady naszych prac - projekty stron internetowych, logo i grafik, które wyróżniają się w sieci"
        />
        <link rel="canonical" href="/realizacja" />
      </Helmet>
      <div>
        <RealizacjaMain />
        <RealizacjaLoga />
        <RealizacjaWizytowki />
      </div>
    </>
  );
};
export default Realizacja;
