// NotFound.js
import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[50vh] text-center p-2">
      <h1 className="text-3xl">404 - Not Found</h1>
      <p className="text-3xl">The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
