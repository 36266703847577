import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { AiOutlineClose } from "react-icons/ai";
import Reveal from "../Animation/Reveal";

import { logoimgs } from "./logoimgs";

const RealizacjaLoga = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const handleImageClick = (index) => {
    setInitialSlide(index);
    setIsOpen(true);
  };

  return (
    <div className="px-2 mt-20">
      <Reveal>
        <h2 className="pb-6 mt-6 text-2xl tracking-wide text-center sm:text-4xl lg:text-5xl">
          Loga
        </h2>
      </Reveal>

      <Reveal>
        <Swiper
          spaceBetween={10}
          initialSlide={initialSlide}
          pagination={{ dynamicBullets: true }}
          modules={[Pagination]}
          breakpoints={{
            768: {
              slidesPerView: 3,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          className="flex flex-wrap justify-center rounded-lg"
          style={{ height: "400px" }}
        >
          {logoimgs.map((photo, index) => (
            <SwiperSlide key={index} onClick={() => handleImageClick(index)}>
              <img
                src={photo.url}
                alt={`Logo ${index}`}
                className="object-contain w-full h-full"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Reveal>
      {isOpen && (
        <div className="fixed inset-0 top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
          <Swiper
            initialSlide={initialSlide}
            pagination={{ dynamicBullets: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="w-full h-[75vh] md:h-[60vh] bg-black"
          >
            {logoimgs.map((photo, index) => (
              <SwiperSlide
                key={index}
                className="flex items-center justify-center w-full h-full bg-white"
              >
                <img
                  src={photo.url}
                  alt={`Swiper image ${index}`}
                  className="max-w-full max-h-full m-auto"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            onClick={() => setIsOpen(false)}
            className="absolute text-white top-4 right-4"
          >
            <AiOutlineClose size={25} />
          </button>
        </div>
      )}
    </div>
  );
};

export default RealizacjaLoga;
