import { Helmet } from "react-helmet-async";
import ExperienceSection from "../components/Main/ExperienceSection";
import Featuresection from "../components/Main/Featuresection";
import Herosection from "../components/Main/Herosection";
import PriceSection from "../components/Main/PriceSection";
import TestimonialsSection from "../components/Main/TestimonialsSection";
import WorkFlow from "../components/Onas/Workflow";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Tworzymy strony internetowe, loga i grafiki, dostosowane do Twoich potrzeb. Nasza firma skupia się na rozwiązaniach, które wyróżnią Twój biznes online."
          type="website"
        />
        <link rel="canonical" href="" />
      </Helmet>
      <div>
        <Herosection />
        <Featuresection />
        <ExperienceSection />
        <WorkFlow />
        <PriceSection />
        <TestimonialsSection />
      </div>
    </>
  );
};
export default Home;
