import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Reveal from "../Animation/Reveal";
import Wave from "react-wavify";
import { useTranslation } from "react-i18next";

const ExperienceSection = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const onChange = (isVisible) => {
    if (isVisible) {
      setVisible(true);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-around bg-white w-full h-[70vh] p-4 text-center  md:h-[30vh] md:flex-row mt-[50px]">
      <Wave
        className="absolute top-[-50px] z-[1]"
        fill="white"
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.2,
          points: 10,
        }}
      />
      <Wave
        className="absolute bottom-[-50px] z-[1]"
        fill="white"
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.2,
          points: 10,
        }}
        style={{ transform: "rotate(180deg)" }}
      />

      <div className="z-[2] flex flex-col md:flex-row w-full items-center justify-around gap-10">
        {" "}
        <Reveal>
          <VisibilitySensor onChange={onChange}>
            <div className="flex flex-col">
              <div className="mb-2 text-4xl font-extrabold text-black xl:text-7xl">
                {visible && <CountUp start={0} end={10} duration={10} />}
                {!visible && "0"} +
              </div>
              <div className="text-black text-s">{t("kient")}</div>
            </div>
          </VisibilitySensor>
        </Reveal>
        <Reveal>
          <VisibilitySensor onChange={onChange}>
            <div className="flex flex-col">
              <div className="mb-2 text-4xl font-extrabold text-black xl:text-7xl">
                {visible && <CountUp start={0} end={15} duration={10} />}
                {!visible && "0"} +
              </div>
              <div className="text-black text-s">{t("projekty")}</div>
            </div>
          </VisibilitySensor>
        </Reveal>
        <Reveal>
          <VisibilitySensor onChange={onChange}>
            <div className="flex flex-col">
              <div className="mb-2 text-4xl font-extrabold text-black xl:text-7xl">
                {visible && <CountUp start={0} end={100} duration={5} />}
                {!visible && "0"} %
              </div>
              <div className="text-black text-s">{t("zaangazowanie")}</div>
            </div>
          </VisibilitySensor>
        </Reveal>
      </div>
    </div>
  );
};
export default ExperienceSection;
